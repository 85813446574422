import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_ePulse/layout/core'
import './Pharmacy/Card.css'
import {useLazyQuery, useQuery} from '@apollo/client'
import {
  GET_DASHBOARD_COUNT_TOP,
  GET_DASHBOARD_REPORT_COUNT,
} from '../../../PharmaModule/GraphQLQueries/querys'
import useApi from '../../../../../hooks/useQuery'
import Card from './Pharmacy/Card'
import {LoadingSpinner} from '../../../../../_ePulse/layout/components/LoadingSpinner'

const PharmaDashboardPage = () => {
  const [count2, setCount2] = useState({
    doctorSaleOrder: 0,
    expirySoon: 0,
    medicineInStock: 0,
    orderRestock: 0,
    registeredDoctor: 0,
    registeredUser: 0,
    registeredVendor: 0,
    totalMonthlyOrder: 0,
    stockAmount:0
  })
  const [count, setCount] = useState({
    quantityOFSoldMedicine: 0,
    invoiceGenerated: 0,
    revenue: 0,
    profit: 0,
  })
  const [isloading, setLoading] = useState(false)

  const getCurrentMonth = () => {
    const currentDate = new Date()
    const month = currentDate.getMonth()
    const year = currentDate.getFullYear()
    return {month, year}
  }

  const getLast12Months = () => {
    const currentMonth = getCurrentMonth()
    const months = []
    let month = currentMonth.month
    let year = currentMonth.year

    for (let i = 0; i < 12; i++) {
      const label = `${getMonthName(month)} ${year}`
      const value = `${getMonthName(month)} ${year}`
      months.unshift({label, value})
      if (month === 0) {
        month = 11
        year--
      } else {
        month--
      }
    }

    return months
  }

  const getMonthName = (monthIndex = 0) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return monthNames[monthIndex]
  }
  type MonthMapping = {
    [key: string]: number // Key is a string (month abbreviation), value is a number (month number)
  }
  const months: MonthMapping = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  }

  const calendraData = getLast12Months()?.reverse()
  const [value, setValue] = useState(calendraData[0]?.value)
  const monthAbbreviation = months[value?.substring(0, 3)]
  const yearDigits = value?.substring(value?.length - 4)
  const [fetchDashboardCounts, {data: dataa, loading: loading1, error: errors, refetch: refetch1}] =
    useLazyQuery(GET_DASHBOARD_COUNT_TOP, {
      fetchPolicy: 'cache-and-network',
    })

  useEffect(() => {
    setLoading(true)
    fetchDashboardCounts()
  }, [fetchDashboardCounts])

  const {
    data: datalist,
    loading: loading3,
    error: error3,
    refetch: refetch3,
  } = useApi(true, GET_DASHBOARD_REPORT_COUNT, {
    month: monthAbbreviation,
    year: parseInt(value?.substring(value?.length - 4)),
  })

  useEffect(() => {
    setLoading(true)
    if (value) {
      refetch3()
      if (datalist) {
        setCount(datalist?.getDashboardReportCount)
        setLoading(false)
      }
    }
  }, [value, datalist])

  // Handle the query result
  useEffect(() => {
    if (dataa) {
      // Handle successful data retrieval
      setCount2(dataa?.getDashboardCounts)
    }
  }, [dataa])

  return (
    <>
      <LoadingSpinner isLoading={isloading} />
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          {/* <p className='heading' style={{paddingLeft: 40}}>
            Dashboard
          </p> */}
        </div>
        <div style={{paddingRight: 41}}>
          <select
            className='form-control'
            value={value}
            onChange={(e: any) => {
              setValue(e.target.value)
            }}
          >
            {calendraData?.map((val) => {
              return <option value={val?.value}>{val?.label}</option>
            })}
          </select>
        </div>
      </div>
      <div className='container'>
        <Card heading='Doctor Orders' count={count2?.doctorSaleOrder} />
        <Card heading='Revenue' count={Number(count?.revenue?.toFixed(2))} />
        <Card heading='Profit' count={Number(count?.profit?.toFixed(2))} />
        <Card heading='Medicine InStock' count={count2?.medicineInStock} />
        <Card heading='Expiry Soon' count={count2?.expirySoon} />
        <Card heading='Order Restock' count={count2?.orderRestock} />
        <Card heading="Total Sotck's Amount" count={Number(count2?.stockAmount?.toFixed(2))} />

      </div>

      <div className='my-pharma-container'>
        <div className='my-pharma-box'>
          <p className='heading'>My Pharmacy</p>
          <div className='a'>
            <div className='top-left'>
              <p className='head'>Doctors</p>
              <p className='count'>{count2?.registeredDoctor}</p>
            </div>
            <div className='top-right'>
              {' '}
              <p className='head'>Users</p>
              <p className='count'> {count2?.registeredUser}</p>
            </div>
            <div className='bottom-left'>
              {' '}
              <p className='head'>Vendors</p>
              <p className='count'> {count2?.registeredVendor}</p>
            </div>
            <div className='bottom-right'>
              <p className='head'>Toatal Monthly Orders</p>
              <p className='count'>{count2?.totalMonthlyOrder}</p>
            </div>
          </div>
          {/* 
          <div className='div'>
            <div className='my'>
              <div>
                <p>Doctors</p>
                <p>o</p>
              </div>
              <div>
                <p>Users</p>
                <p>o</p>
              </div>
            </div>
            <div className='my'>
              <div>
                <p>Vendors</p>
                <p>o</p>
              </div>
              <div>
                <p>Toatal Monthly Orders</p>
                <p>o</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className='my-pharma-box'>
          <p className='heading'>Quick Reports</p>
          <div className='div'>
            <div className='my'>
              <div>
                <p className='head'>Qty of Medicines Sold</p>
                <p className='count'>{count?.quantityOFSoldMedicine}</p>
              </div>
              <div>
                <p className='head'>Invoices Generated</p>
                <p className='count'>{count?.invoiceGenerated}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PharmaDashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <PharmaDashboardPage />
    </>
  )
}

export {PharmaDashboardWrapper}
