import {gql} from '@apollo/client'


export const GET_STAFF_LIST = gql`
  query getAllStaffMemberList($limit: Int!, $page: Int!) {
    getAllStaffMemberList(listFilter: {orderBy: "", sortBy: "", page: $page, limit: $limit}) {
      data {
        id
        firstName
        lastName
        mobileNumber
        email
        staffRole
      }
      total
      pageSize
      page
      totalPages
    }
  }
`

export const CREATE_STAFF_MEMBER = gql`
  mutation createStaffMember(
    $firstName: String!
    $lastName: String!
    $mobileNumber: MobileNumber!
    $isActive: Boolean!
    $email: String!
    $staffRoleId: ID!
    $gender: GENDER!
    $mciNumber: String
    $stateCouncil: String
    $speciality: String
  ) {
    createStaffMember(
      staffMemberInput: {
        firstName: $firstName
        lastName: $lastName
        mobileNumber: $mobileNumber
        isActive: $isActive
        email: $email
        staffRoleId: $staffRoleId
        gender: $gender
        technicalDetails: {
          mciNumber: $mciNumber
          stateCouncil: $stateCouncil
          speciality: $speciality
        }
      }
    ) {
      id
      firstName
      lastName
      mobileNumber
      email
      staffRoleId
    }
  }
`

export const GET_STAFF_DETAILS = gql`
  query GetStaffMemberById($id: ID!) {
    getStaffMemberById(id: $id) {
      id
      firstName
      lastName
      mobileNumber
      email
      staffRoleId
      gender
      isActive
      technicalDetails {
        mciNumber
        stateCouncil
        speciality
      }
    }
  }
`

export const EDIT_STAFF_MEMBER = gql`
  mutation (
    $staffMemberId: ID!
    $firstName: String!
    $lastName: String!
    $mobileNumber: MobileNumber!
    $isActive: Boolean!
    $email: String!
    $staffRoleId: ID!
    $gender: GENDER!
    $mciNumber: String
    $stateCouncil: String
    $speciality: String
  ) {
    editStaffMemberProfile(
      staffMemberId: $staffMemberId
      staffMemberInput: {
        firstName: $firstName
        lastName: $lastName
        mobileNumber: $mobileNumber
        isActive: $isActive
        email: $email
        staffRoleId: $staffRoleId
        gender: $gender
        technicalDetails: {
          mciNumber: $mciNumber
          stateCouncil: $stateCouncil
          speciality: $speciality
        }
      }
    ) {
      id
      firstName
      lastName
      mobileNumber
      mobileNumber
      profilePhoto
      email
      staffRoleId
      staffRole
      gender
      isActive
    }
  }
`
