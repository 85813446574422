/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'

import {toast} from 'react-toastify'
import useMutationApi from '../../../../../../../../hooks/useMutation'

import {success} from '../../../../../../../../data/Status/success'
import {ToastMessageContainer} from '../../../../../../../../_ePulse/layout/components/ToastMessageContainer'
import { DELETE_PATIENT } from '../../../../../../ScanCenterModule/GraphQLQueries/Patients'

export function DeletePatientModal(props: any) {
  const [id, setid] = useState(props.idToEdit)

  const {createData, loading, error, data} = useMutationApi(DELETE_PATIENT)

  const [isloading, setLoading] = useState(false)

  const deleteCallAPI = async () => {
    try {
      const res = await createData({
        userRoleId: id,
      })

      toast.success(success.PATIENT_DELETED)

      props.closeModal(false)

      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  return (
    <div>
      <ToastMessageContainer />
      <Modal
        show={props.modalState}
        dialogClassName='ePulse-modal ePulse-modal-50w'
        onHide={() => props.closeModal(false)}
        backdrop='static'
      >
        <Modal.Header className='border-0' closeButton>
          <span className='fs-3 fw-bold'>Delete Summary</span>
        </Modal.Header>
        <Modal.Body className='container' />
        <div className='ePulse-modal-content'>
          <span className='fs-3 fw-semibold ms-4'>Are you sure, you want to delete?</span>
          <div className='btn-row'>
            <button className='ePulse-btn-primary' type='button' onClick={() => deleteCallAPI()}>
              {!isloading && 'Delete'}
              {isloading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
