// useApi.js

import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { actionsUsers } from '../setup/redux/rootReducer'

const useApi = (toastmessage: any, query: any, variables: any) => {
  const dispatch = useDispatch()
  const { loading, error, data, refetch }: any = useQuery(query, { variables })

  if (error) {

    const sessionExpirePattern = /session\s*expire/i
    // if (sessionExpirePattern.test(error?.message)) {
    //   toast.error('Session Expired! Please login again.')
    //   setTimeout(() => {
    //     dispatch(actionsUsers.logout())
    //   }, 3000)
    // } else {
    //   toast.error(`${error?.message}`)
    // }

    // if (sessionExpirePattern.test(error?.networkError?.result?.errors[0]?.message)) {
    //   if (toastmessage) {
    //     toast.error('Session Expired! Please login again.')
    //     setTimeout(() => {
    //       dispatch(actionsUsers.logout())
    //     }, 3000)
    //   }
    // } else if (error.networkError.statusCode === 500) {
    //   toast.error(error?.networkError?.result?.errors[0]?.message)
    // } else {
    //   toast.error(`${error?.message}`)
    // }

    // if (error?.networkError?.statusCode === 440) {
    //   if (toastmessage) {
    //     toast.error(`${error?.networkError?.result?.errors[0]?.message}`)
    //     setTimeout(() => {
    //       dispatch(actionsUsers.logout())
    //     }, 3000)
    //   }
    // } else {
    //   toast.error(`${error?.message}`)
    // }
  }

  return {
    loading,
    error,
    data,
    refetch,
  }
}

export default useApi
