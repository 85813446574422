/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

import moment from 'moment'
import {useQuery} from '@apollo/client'
import useApi from '../../../../hooks/useQuery'

const PatientsQueue = () => {
  const [listing, setList] = useState([])

  // const {
  //   data,
  //   loading: loading4,
  //   error: error4,
  //   refetch: refetch4,
  // } = useApi(true, GET_PATIENT_LIST_DASHBOARD, {
  //   date: moment().format(),
  // })

  // useEffect(() => {
  //   if (data) {
  //     setList(data?.getScheduledAppointmentListByDate)
  //   }
  // }, [data])

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Patients Queue</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Latest tech trends</span> */}
        </h3>
        <div className='card-toolbar'>
          {/* <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'1'}
            >
              <option></option>
              <option value='1'>Morning Slot</option>
              <option value='2'>Noon Slot</option>
              <option value='3'>Evening Slot</option>
              <option value='4'>Cancelled</option>
            </select>
          </div> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {listing.length ? (
          <div className='d-flex align-items-sm-center mb-3 bg-light py-3 px-1'>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Shefali Aggrawal
                </span>
                <div className='d-flex col-4 bg-warning align-items-center justify-content-center rounded-pill'>
                  <span className='text-black fw-semibold d-block fs-8'>Consultation</span>
                </div>
              </div>
              <img src={toAbsoluteUrl('/media/ePulse/watch.svg')} alt='' />
              <span className='badge badge-light fw-bold my-2 text-primary'>9:00 - 9:30 </span>
            </div>
          </div>
        ) : (
          <div className='text-center fs-5 fw-semibold'>No data found</div>
        )}
      </div>
    </div>
  )
}

export {PatientsQueue}
