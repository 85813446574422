import {Routes, Route} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_ePulse/layout/core'
import { PatientListTable } from './table-list/table/PatientListTable'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const ProfilePages = () => (
  <Routes>
    <Route
      path='/'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>Patient</PageTitle>
          <PatientListTable />
        </>
      }
    />
    <Route
      path='/add'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>Create RX</PageTitle>
        </>
      }
    />
    <Route
      path='/edit'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>Edit RX</PageTitle>
        </>
      }
    />
    <Route
      path='/view'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>View RX</PageTitle>
        </>
      }
    />
    <Route
      path='/editclone'
      element={
        <>
          <PageTitle breadcrumbs={breadcrumbs}>Edit RX</PageTitle>
        </>
      }
    />
  </Routes>
)

export default ProfilePages
