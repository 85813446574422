import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// const store = configureStore({
//   reducer: rootReducer,
// })

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
})

const persistor = persistStore(store)

export {persistor}

export default store
