import {gql} from '@apollo/client'

export const GET_STAFF_ROLL = gql`
  query {
    getStaffRoleList {
      id
      name
      isActive
    }
  }
`
export const GET_DOCTOR_SPECIALITY_LIST = gql`
  query {
    getDoctorSpecialityList(listFilter: {orderBy: "", sortBy: "", page: 1, limit: 999999999}) {
      data {
        id
        name
        description
        isActive
      }
      total
      pageSize
      page
      totalPages
    }
  }
`

export const GET_STATE_COUNCIL_LIST = gql`
  query {
    getStateCouncilList(listFilter: {orderBy: "", sortBy: "", page: 1, limit: 999999999}) {
      data {
        id
        name
        description
        isActive
      }
      total
      pageSize
      page
      totalPages
    }
  }
`

export const GET_APPOINTMENT_SETTING = gql`
  query {
    getAppointmentTime {
      appointmentDurationTime
      appointmentDurationUnit
    }
  }
`

export const EDIT_APPOINTMENT_SETTING_MUTATION = gql`
  mutation editAppointmentTime($appointmentDurationTime: Int, $appointmentDurationUnit: String) {
    editAppointmentTime(
      appDurInput: {
        appointmentDurationTime: $appointmentDurationTime
        appointmentDurationUnit: $appointmentDurationUnit
      }
    )
  }
`
