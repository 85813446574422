/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useSelector, useDispatch} from 'react-redux'

import {useMutation} from '@apollo/client'

import {useLocation, useNavigate} from 'react-router-dom'
import useMutationApi from '../../../../../hooks/useMutation'
import {OTP_MUTATION} from '../../../GraphQLQuries/Auth'
import {actionsUsers} from '../../../../../setup/redux/rootReducer'

const loginSchema = Yup.object().shape({
  mpin: Yup.string().required('MPIN is required'),
})

const initialValues = {
  mpin: '',
}

export function MPIN() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {browserUniqueId, ipAddress, userAgent, browserName, countryCode, mpinLength}: any =
    location?.state
  const userData: any = useSelector((state) => {
    return state
  })

  const [loadingLoader, setLoading] = useState(false)

  const {createData, data, loading, error} = useMutationApi(OTP_MUTATION)

  const [status, setstatus] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (
      values
      // , {setStatus, setSubmitting}
    ) => {
      setLoading(true)
      try {
        const mobileNumber = userData.user.mobile_number

        const res = await createData({
          mobileNumber,
          countryCode: parseInt(countryCode),
          otp: values.mpin,
          type: 'M_PIN',
          browserUniqueId: browserUniqueId,
          ipAddress: ipAddress,
          userAgent: userAgent,
          browserName: browserName,
        })

        dispatch(actionsUsers.updateState(res?.data?.verifyWebAdminOtp))
        dispatch(actionsUsers.sessionTrackingStart())
        dispatch(
          actionsUsers.updateUser({
            mobile_number: userData.user.mobile_number,
            _id: res?.data?.verifyWebAdminOtp.userId,
          })
        )
        navigate(`/auth/business`, {
          state: {browserUniqueId, ipAddress, userAgent, browserName},
        })

        setLoading(false)
      } catch (error) {
        setstatus(true)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          Enter MPIN
          {/* <a className='' style={{color: '#000FD0'}}>
            ePulse
          </a> */}
        </h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className=' fw-bolder' style={{color: '#000FD0'}}>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {error && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error?.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>MPIN</label>
        <input
          placeholder='Enter MPIN'
          {...formik.getFieldProps('mpin')}
          className={clsx('form-control form-control-lg form-control-solid')}
          type='text'
          name='mpin'
          autoComplete='off'
          maxLength={mpinLength}
        />
        {formik.touched.mpin && formik.errors.mpin && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger'>{formik.errors.mpin}</span>
          </div>
        )}
      </div>

      {/* end::Form group */}
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  w-100 mb-5 rounded-pill text-white'
          //   disabled={formik.isSubmitting || !formik.isValid}
          style={{backgroundColor: '#000FD0'}}
        >
          {!loadingLoader && <span className='indicator-label'>Continue</span>}
          {loadingLoader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
