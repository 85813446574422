/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {DoctorRoutes} from './DoctorRoutes'

import {App} from '../App'
import {useSelector, useDispatch} from 'react-redux'

import {PhamraRoutes} from './PhamraRoutes'
import {ScanRoutes} from './ScanRoutes'
import {ErrorsPage} from '../common/modules/errors/ErrorsPage'
import {AuthPage, Logout} from '../common/modules/auth'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const dataUser: any = useSelector((state) => {
    return state
  })
  const role: any = dataUser?.auth?.role?.toLowerCase().replaceAll('_', '')
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {dataUser.auth && dataUser.auth.tokenType === 'PERMANENT' ? (
            <>
              {dataUser?.auth?.role === 'Doctor' && (
                <>
                  <Route path={`${role}/*`} element={<DoctorRoutes />} />
                  <Route path='*' element={<Navigate to={`${role}/dashboard`} />} />
                  {/* <Route index element={<Navigate to='/dashboard' />} /> */}
                </>
              )}

              {dataUser?.auth?.role === 'Pharmacy' && (
                <>
                  <Route path={`${role}/*`} element={<PhamraRoutes />} />
                  <Route path='*' element={<Navigate to={`${role}/dashboard`} />} />
                  {/* <Route index element={<Navigate to='/pharmacy/dashboard' />} /> */}
                </>
              )}

              {dataUser?.auth?.role === 'ScanCenter' && (
                <>
                  <Route path={`${role}/*`} element={<ScanRoutes />} />
                  <Route path='*' element={<Navigate to={`${role}/dashboard`} />} />
                  {/* <Route index element={<Navigate to='/scan/dashboard' />} /> */}
                </>
              )}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
