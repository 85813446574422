import React from 'react'
import './Card.css' // Import the CSS file for styles

const Card = ({heading = '', count = 0, isScan = false}) => {
  const getCountValue = (num: any) => {
    if (heading === 'Revenue' || heading === 'Profit' || heading === "Total Sotck's Amount") {
      return num?.toLocaleString('en-US')
    }
    return num
  }

  return (
    <div className={isScan ? 'scanBox' : 'box'}>
      <p className='top-head'>{heading}</p>
      <div>
        <p className={heading === 'Expiry Soon' ? 'cont-text red' : 'cont-text'}>
          {' '}
          {heading === 'Revenue' || heading === 'Profit' || heading === "Total Sotck's Amount"
            ? '₹'
            : ''}{' '}
          {getCountValue(count)}
        </p>
      </div>
    </div>
  )
}

export default Card
