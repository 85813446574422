import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import '../../App.css'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  NotificationMessenger,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import useMutationApi from '../../hooks/useMutation'
import {SET_DEFAULT_BUSINESS_ADD} from '../../app/common/GraphQLQuries/SwitchBusiness'
import {success} from '../../data/Status/success'
import {toast} from 'react-toastify'
import {ToastMessageContainer} from './components/ToastMessageContainer'
import {Modal} from 'react-bootstrap'
import {events, logoutTime} from '../../data/config'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../../setup/redux/rootReducer'
import {LOGOUT} from '../../app/common/GraphQLQuries/Auth'

const SessionModal = (props: any) => {
  const {createData, loading, error, data} = useMutationApi(SET_DEFAULT_BUSINESS_ADD)
  const setDefault = async () => {
    const res = await createData({
      id: props?.idToEdit,
    })
    toast.success(success.PROVIDER_UPDATED)
    props.setModalState()
  }

  return (
    <div>
      <ToastMessageContainer />
      <Modal
        show={props.modalState}
        dialogClassName='ePulse-modal ePulse-modal-50w'
        onHide={() => props.setModalState()}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <span className='fs-3 fw-bold'>Session Expire</span>
        </Modal.Header>
        <Modal.Body className='container'></Modal.Body>
        <div className='ePulse-modal-content'>
          <div className='bg-white rounded p-3 col'>
            <span>
              Your current session is expired due to inactivity.
              <br />
            </span>

            <span>
              To continue your session, select <span className='fw-bold'>Stay Signed In.</span>
            </span>
          </div>

          <div className='btn-row'>
            <button
              className='ePulse-btn-primary'
              type='button'
              onClick={() => props.setModalState()}
            >
              Stay Signed In
            </button>

            <button
              className='ms-3 ePulse-btn-secondory'
              type='button'
              onClick={() => props.logout()}
            >
              Sign Out
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const MasterLayout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  let hide_header_aside = false
  if (
    (location.pathname.startsWith('/doctor/prescription-history/pdf-preview') &&
      location.pathname.endsWith('.pdf')) ||
    location.pathname.startsWith('/doctor/prescription-history/print')
  ) {
    hide_header_aside = true
  }
  const [sessionModalState, setSessionModalState] = useState(false)
  const dataUser: any = useSelector((state) => {
    return state
  })
  const sessionTracking: any = useSelector((state) => {
    return state
  })
  const isAuthPath = /\/auth(\/forgot-password|\/registration|\/otp|\/mpin)?$/ // Matches /auth, /auth/forget, /auth/registration,/auth/password

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    if (sessionTracking.sessionTracking) {
      
      // handleLogoutTimer()
      // Start the timer immediately when the component mounts
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer()
          handleLogoutTimer()
        })
      })
    }
  }, [dataUser, sessionTracking])

  let timer: any
  let countdownInterval: any
  // this function sets the timer that logs out the user after set time
  const handleLogoutTimer = () => {
    const duration = logoutTime(
      dataUser?.auth?.applicationSetting?.business_admin_web_expiration_time,
      dataUser?.auth?.applicationSetting?.business_admin_web_expiration_unit
    )
    // console.log(`Setting logout timer for ${duration}ms`)
    // Start the countdown interval
    let remainingTime = duration / 1000 // Convert ms to seconds for countdown
    countdownInterval = setInterval(() => {
      remainingTime -= 1
      // console.log(`Time remaining before logout: ${remainingTime} seconds`)

      if (remainingTime <= 0) {
        clearInterval(countdownInterval)
      }
    }, 1000)

    timer = setTimeout(() => {
      console.log('Logging out due to inactivity')
      // alert('You have been logged out due to inactivity.')
      setSessionModalState(true)
      // clears any pending timer.
      resetTimer()
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        // console.log(`Removing event listener for ${item}`)
        window.removeEventListener(item, resetTimer)
      })
      // logs out user
    }, duration) // 300000ms = 5 min. You can change the time.
  }

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) {
      // console.log('Clearing existing timer')
      clearTimeout(timer)
    }
    if (countdownInterval) {
      // console.log('Clearing countdown interval')
      clearInterval(countdownInterval)
    }
  }

  const {createData, loading: loading2, error, data} = useMutationApi(LOGOUT)

  const logoutUser = async () => {
    const response = await createData({logoutType: 'one'})
    dispatch(actionsUsers.logout())
    dispatch(actionsUsers.sessionTrackingStop())
  }

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root ePulse-BG-Outlet'>
        {/* begin::Page */}
        <div className='page d-flex flex-row flex-column-fluid'>
          {location.pathname !== '/doctor/prescription-history/print' &&
            hide_header_aside === false && <AsideDefault />}
          {/* begin::Wrapper */}
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            {location.pathname !== '/doctor/prescription-history/print' &&
              hide_header_aside === false && <HeaderWrapper />}
            {/* {location.pathname === '/dashboard' && <Toolbar />} */}

            {/* begin::Content */}
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* end::Content */}
            {/* <Footer /> */}
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger />
      <NotificationMessenger />
      {sessionModalState && (
        <SessionModal
          modalState={sessionModalState}
          setModalState={() => {
            setSessionModalState(false)
          }}
          logout={() => {
            logoutUser()
          }}
        />
      )}

      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
