import React from 'react'
import {useState} from 'react'
import clsx from 'clsx'
import {useLocation, useNavigate} from 'react-router-dom'
import { setting_tabs, setting_tabs_scan } from '../../../data/config'
import { PDFTab } from './PDFTab'

function PDF() {
  const [isTabMenu, setIsTabMenu] = useState(true)
  const openTabMenu = () => {
    console.log('Success')
    setIsTabMenu(!isTabMenu)
  }

  const navigate = useNavigate()
  const location = useLocation()

  const filterTitle = () => {
    const obj: any = setting_tabs?.filter((e: any) => e.path === location.pathname)
    return obj[0]?.title
  }

  return (
    <div className='d-flex flex-column form-wrapper'>
      <h2 className='page-title'>Booking Slot Settings</h2>
      <hr />
      <div className='vertical-tabs'>
      
        <div className='tab-content'>
          <PDFTab />
        </div>
      </div>
    </div>
  )
}

export default PDF
