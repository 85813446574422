/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../_ePulse/assets/ts/components'
import {KTSVG} from '../../../../../../../_ePulse/helpers'
import {EditPatientModal} from '../modals/EditPatient'
import {DeletePatientModal} from '../modals/DeletePatient'
import {ViewPatientModal} from '../modals/ViewPatient'
import {checkPermission} from '../../../../../../App'

// type Props = {
//   id: ID
// }

const ActionsCell = (props: any) => {
  const navigate = useNavigate()
  useEffect(() => {
    MenuComponent.reinitialization()
    props.refetch()
  }, [])

  const [editModal, seteditModal] = useState(false)
  const [deleteModal, setdeleteModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)

  const closeModal = () => {
    seteditModal(false)
    setdeleteModal(false)
    setViewModal(false)
    props.refetch()
  }

  return (
    <div className='d-flex ms-5'>
      {editModal && (
        <EditPatientModal
          modalState={editModal}
          closeModal={() => closeModal()}
          idToEdit={props?.tableProps?.userRoleId}
          // refetch={props.refetch}
        />
      )}

      {deleteModal && (
        <DeletePatientModal
          modalState={deleteModal}
          closeModal={() => closeModal()}
          idToEdit={props?.tableProps?.userRoleId}
        />
      )}
      {viewModal && (
        <ViewPatientModal
          modalState={viewModal}
          closeModal={() => closeModal()}
          idToEdit={props?.tableProps?.userRoleId}
        />
      )}

      {/* {checkPermission('updatePolicy') && ( */}
      {/* <div className='d-flex flex-row align-items-center justify-content-center'>
        <button
          onClick={() => setVersionCodeModal(true)}
          className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
        >
          <KTSVG path='/media/ePulse/view_icon.svg' className='m-0' />
        </button>
        {!props.tableProps.isMasterTemplate && (
          <button
            onClick={() => seteditModal(true)}
            className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
          >
            <KTSVG path='/media/ePulse/edit_icon.svg' className='m-0' />
          </button>
        )}
      </div> */}

      <a
        href='javascript:void(0);'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/ePulse/dots_vertical_icon.svg' className='svg-icon-2 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {checkPermission('editBusinessPatient') && (
          <div className='fld-ex align-items-center justify-content-center py-1'>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => seteditModal(true)}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                Edit
              </button>
            </div>
          </div>
        )}
        {checkPermission('deleteBusinessPatient') && (
          <div className='fld-ex align-items-center justify-content-center py-1 border'>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => setdeleteModal(true)}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                Delete
              </button>
            </div>
          </div>
        )}
        {checkPermission('getBusinessUserProfile') && (
          <div className='fld-ex align-items-center justify-content-center py-1 border'>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => setViewModal(true)}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                View
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {ActionsCell}
