/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../../../../../../../App.css'
import {toAbsoluteUrl} from '../../../../../../../../_ePulse/helpers'

export function Template1(props: any) {
  const {
    name,
    qualification,
    address,
    email,
    mobileNumber1,
    mobileNumber2,
    hospital,
    website,
    doctorImage,
    logoImage,
    signature,
  } = props

  const backgroundStyle = {
    doctorImage: `url(${doctorImage})`, // Set the value of the background-image property using the URL of the dynamic image
    backgroundSize: 'cover',
    // backgroundPosition: 'center center',
    // width: '100%',
    // height: '100vh',
  }

  return (
    // <div className='p-2 '>
    //   <div
    //     className='d-flex flex-row justify-content-between align-items-center p-3 '
    //     className={{backgroundColor: '#1F1F1F'}}
    //   >
    //     <img src={toAbsoluteUrl('/media/ePulse/logo3.png')} alt='' width={30} height={30} />
    //     <span className='text-white fs-4 font-face-MuseoSans100'>{hospital}</span>
    //   </div>
    //   <div className='d-flex flex-row mt-3 align-items-center '>
    //     {doctorImage && (
    //       <img
    //         src={doctorImage}
    //         alt=''
    //         className='rounded-circle ms-4'
    //         width={'45px'}
    //         height={'45px'}
    //       />
    //     )}
    //     <div className='col d-flex flex-column px-2'>
    //       <span
    //         className={{color: '#1F1F1F', lineHeight: 1}}
    //         className=' fs-3 fw-bold font-face-MuseoSans700'
    //       >
    //         {name}
    //       </span>
    //       <span className={{color: '#1F1F1F', lineHeight: 1}} className=' fs-4 font-face-MuseoSans500'>
    //         {qualification}
    //       </span>
    //     </div>
    //     {logoImage && <img src={logoImage} width={50} height={40} alt='' className='me-3' />}
    //   </div>
    //   <div className='h-300px bg-white w-100 ' />
    //   <div className='d-flex flex-row justify-content-between '>
    //     <div className='d-flex flex-column align-items-center'>
    //       {signature && <img src={signature} className='rounded' width={80} height={40} alt='' />}

    //       <div className=' border-dark border-top'>Signature</div>
    //     </div>
    //     <div className='d-flex flex-column align-items-end'>
    //       <div className='d-flex flex-row justify-content-center align-items-center mb-3'>
    //         <div className='d-flex flex-column'>
    //           <span
    //             className='fs-7 font-face-MuseoSans100 '
    //             className={{color: '#1F1F1F', lineHeight: 1}}
    //           >
    //             {mobileNumber1}
    //           </span>
    //           <span
    //             className='fs-7 font-face-MuseoSans100'
    //             className={{color: '#1F1F1F', lineHeight: 1}}
    //           >
    //             {mobileNumber2}
    //           </span>
    //         </div>
    //         <img
    //           src={toAbsoluteUrl('/media/ePulse/Phone.png')}
    //           alt=''
    //           width={15}
    //           height={15}
    //           className='ms-2'
    //         />
    //       </div>
    //       <div className='d-flex flex-row justify-content-center align-items-center mb-3'>
    //         <div className='d-flex flex-column'>
    //           <span
    //             className='fs-7 font-face-MuseoSans100'
    //             className={{color: '#1F1F1F', lineHeight: 1}}
    //           >
    //             {website}
    //           </span>
    //           <span
    //             className='fs-7 font-face-MuseoSans100'
    //             className={{color: '#1F1F1F', lineHeight: 1}}
    //           >
    //             {email}
    //           </span>
    //         </div>
    //         <img
    //           src={toAbsoluteUrl('/media/ePulse/WEB.png')}
    //           alt=''
    //           width={15}
    //           height={15}
    //           className='ms-2'
    //         />
    //       </div>
    //       <div className='d-flex flex-row justify-content-center align-items-center mb-3'>
    //         <div className='d-flex flex-column'>
    //           <span
    //             className='fs-7 font-face-MuseoSans100 flex-wrap w-150px'
    //             className={{color: '#1F1F1F', lineHeight: 1}}
    //           >
    //             {address}
    //           </span>
    //         </div>
    //         <img
    //           src={toAbsoluteUrl('/media/ePulse/Location.png')}
    //           alt=''
    //           width={15}
    //           height={15}
    //           className='ms-2'
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   <div className=''>
    //     <img src={toAbsoluteUrl('/media/ePulse/Footer1.svg')} alt='' className='w-100' />
    //   </div>
    // </div>

    <div className='template-container'>
      <div className='template-header'>
        <div className='header-content'>
          <img
            className='template-logo'
            src={toAbsoluteUrl('/media/ePulse/logoInverse.svg')}
            alt=''
          />
          <h2 className='text-white ms-3'>Healthcare made simple</h2>
        </div>
      </div>
      <div className='doctor-details'>
        <div className='line-height: 1.2; margin-left: 1rem;'>
          <span className='label-3;'>Dr. Vijay Sankar Sharma</span>
          <br />
          <span className='label-4'>Cardiologist</span>
          <br />
          +91-1020304050
        </div>
      </div>
      <div className='seperator-line' />
      <div className='template-body'>
        <div className='patient-details'>
          <div className='patient-details-box'>
            <div>
              <span className='label'>Patient Name: </span> [Patient Name]
            </div>
            <div>
              <span className='label'>Gender/Age/Dob: </span> Male / 37 Yrs / 04/01/76
            </div>
            <div>
              <span className='label'>Patient Phone No: </span> [0102030405]
            </div>
          </div>
          <div className='patient-details-box'>
            <div>
              <span className='label'>Consultatlon Date</span> 26/10/2023
            </div>
            <div>
              <span className='label'>Consultant</span> Dr. Vijay Pratap
            </div>
            <div>
              <span className='label'>Consultatlon Type</span> Clinic Appointment
            </div>
          </div>
        </div>
        <div className='seperator-line'></div>
        <div className='magin-20'>
          <div className='label category'>Vitals</div>
          <div className='margin-10'>
            <div className='label'>Basic Vitals</div>
            <div>CVS: 1234</div>
            <div>Blood Pressure: 120/80 mmHg</div>
          </div>
          <div className='margin-10'>
            <div className='label'>Selective Vitals</div>
            <div>CVS: 1234</div>
            <div>Blood Pressure: 120/80 mmHg</div>
          </div>
        </div>
        <div className='magin-20'>
          <div className='label category'>Complaints</div>
          <div>
            Itchy palms, cardiomegaly, strawberry cervix, eyeball is swollen, itchy eye, clogged
            pores in bald spots, sternal lift
          </div>
        </div>
        <div className='magin-20'>
          <div className='label category'>Diagnosis</div>
          <div>Obesity, Hypertension</div>
        </div>
        <div className='magin-20'>
          <div className='label category'>Rx</div>
          <table
            className='w-100'
            // cellpadding="0" cellspacing="0"
          >
            <tr className='label'>
              <td className='table-head'></td>
              <td className='table-head'>Medicine</td>
              <td className='table-head'>Dose</td>
              <td className='table-head'>Timing</td>
              <td className='table-head'>Duration</td>
            </tr>
            <tr>
              <td className='table-data'>1</td>
              <td className='table-data'>
                <span className='label'>Dolo 50mg</span> <br />
                <span className='label-2'>(Paracetamol)</span>
              </td>
              <td className='table-data'>0-0-1</td>
              <td className='table-data'>After meal</td>
              <td className='table-data'>5 days</td>
            </tr>
            <tr>
              <td className='table-data'>2</td>
              <td className='table-data'>
                <span className='label'>Omnacortil 10 MG Tablet</span> <br />
                <span className='label-2'>(Prednisolone)</span>
              </td>
              <td className='table-data'>1-0-1</td>
              <td className='table-data'>After meal</td>
              <td className='table-data'>7 days</td>
            </tr>
            <tr>
              <td className='table-data'>3</td>
              <td className='table-data'>
                <span className='label'>Advil 10 MG Drops</span> <br />
                <span className='label-2'>(Ibuprofen)</span>
              </td>
              <td className='table-data'>1-1-1</td>
              <td className='table-data'>before meal</td>
              <td className='table-data'>10 days</td>
            </tr>
          </table>
        </div>
        <div className='magin-20'>
          <div className='label category'>Test Required</div>
          <div>ECG, FBS</div>
        </div>
        <div className='magin-20'>
          <div className='label category'>Advice</div>
          <div>Drink more water, Exercise Regularly</div>
        </div>
      </div>
      <div className='seperator-line' />
      <div className='template-footer'>
        <div>
          <span className='label-3;'>Dr. Vijay Sankar Sharma</span>
          <br />
          <span className='label-4'>C-Scheme, Jaipur</span>
        </div>
        <div>
          <span>
            <img src='/media/ePulse/phone_logo.svg' className='footer-img' alt='' />
            +91-1020304050
          </span>
          <br />
          <span>
            <img src='/media/ePulse/email_logo.svg' className='footer-img' alt='' />
            vijay.sharma@getwell.com
          </span>
        </div>
      </div>
      <div className='footer-line-1' />

      <div className='footer-line-2' />
    </div>
  )
}
