/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import '../../../../../../../App.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {toast} from 'react-toastify'
import useApi from '../../../../../../../hooks/useQuery'
import {GET_PATIENT_BY_ID} from '../../../../GraphQLQueries/Patients'
import {GET_COUNTRIES_CODE} from '../../../../GraphQLQueries/common'
import useMutationApi from '../../../../../../../hooks/useMutation'
import {success} from '../../../../../../../data/Status/success'
import {checkMobileNoValid, checkzipcodeValid} from '../../../../../../../data/config'
import {ToastMessageContainer} from '../../../../../../../_ePulse/layout/components/ToastMessageContainer'
import {EDIT_PATIENT} from '../../../../../DoctorModule/GraphQLQueries/Patients'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const Schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  countryCode: Yup.string().required('Country is required').not(['default'], 'Select Country Code'),
  mobileNumber: Yup.number().required('Mobile number is required'),
})

const initialValues = {
  firstName: '',
  lastName: '',
  gender: '',
  email: '',
  zipCode: '',
  street: '',
  city: '',
  district: '',
  state: '',
  country: '',
  countryCode: '',
  mobileNumber: '',
  dob: '',
  // isPdfPrinted: true,
}

export function EditPatientModal(props: any) {
  const [countryCodeList, setcountryCode] = useState<any>([])
  const {
    data: data6,
    loading: loading6,
    error: error6,
    refetch: refectch6,
  } = useApi(true, GET_PATIENT_BY_ID, {
    id: props.idToEdit,
  })

  const {
    data: countryCodeData,
    loading: loading1,
    error: error2,
    refetch: refetch2,
  } = useApi(true, GET_COUNTRIES_CODE, {})

  useEffect(() => {
    if (countryCodeData) {
      const res = countryCodeData?.getCountries
      setcountryCode(res)
    }
    refectch6()
  }, [countryCodeData])

  useEffect(() => {
    if (data6) {
      const res = data6?.getBusinessUserProfile
      formik.setValues({
        ...formik.values,
        firstName: res?.userProfile?.firstName || '',
        lastName: res?.userProfile?.lastName || '',
        gender: res?.userProfile?.gender || 'default',
        email: res?.userProfile?.email || '',
        zipCode: res?.userAddress?.zipCode || '',
        street: res?.userAddress?.street || '',
        city: res?.userAddress?.city || '',
        district: res?.userAddress?.district || '',
        state: res?.userAddress?.state || '',
        country: res?.userAddress?.country || 'default',
        countryCode: res?.countryCode || 'default',
        mobileNumber: res?.mobileNumber || '',
        dob: res?.userProfile?.dob || '',
      })
    }
  }, [data6])

  const {createData, loading, error, data} = useMutationApi(EDIT_PATIENT)

  const [isloading, setLoading] = useState(false)

  const editCategoryCallAPI = async (values: any) => {
    try {
      const res = await createData({
        userRoleId: props?.idToEdit,
        firstName: values?.firstName,
        lastName: values?.lastName,
        gender: values?.gender === 'default' ? undefined : values?.gender,
        email: values?.email,
        countryCode: values?.countryCode,
        mobileNumber: values?.mobileNumber,
        zipCode: values?.zipCode === '' ? undefined : JSON.stringify(values?.zipCode),
        address: values?.street === '' ? '' : values?.street,
        city: values?.city === '' ? undefined : values?.city,
        district: values?.district === '' ? undefined : values?.district,
        state: values?.state === '' ? undefined : values?.state,
        country: values?.country === 'default' ? undefined : values?.country,
        dob: values?.dob,
      })
      toast.success(success.PATIENT_UPDATED)
      // setTimeout(() => {

      // props.refetch()
      props.closeModal(false)

      // }, 1000)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        editCategoryCallAPI(values)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    },
  })

  const changeMobileNo = (value: any) => {
    // const number = value
    // if (/^\d*$/.test(number) && number.length <= 10) {
    //   formik.setFieldValue('mobileNumber', parseInt(value))
    // }
    const valid = checkMobileNoValid(value)
    if (valid) {
      formik.setFieldValue('mobileNumber', parseInt(value))
    }
  }

  const changezipcode = (value: any) => {
    // const number = value
    // if (/^\d*$/.test(number) && number.length <= 10) {
    //   formik.setFieldValue('mobileNumber', parseInt(value))
    // }
    const valid = checkzipcodeValid(value)
    if (valid) {
      formik.setFieldValue('zipCode', parseInt(value))
    }
  }

  return (
    <div>
      <ToastMessageContainer />
      <Modal
        show={props.modalState}
        dialogClassName='ePulse-modal ePulse-modal-70w'
        onHide={() => props.closeModal(false)}
        backdrop='static'
      >
        <Modal.Header className='border-0' closeButton>
          <span className='fs-3 fw-bold'>Edit Patient</span>
        </Modal.Header>
        <Modal.Body className='container' />
        <div className='ePulse-modal-content'>
          <form onSubmit={formik.handleSubmit}>
            <div className='d-flex flex-column justify-content-between mb-4'>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2 required'>First Name</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('firstName')}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.firstName}</span>
                    </div>
                  )}
                </div>
                <div className='col form-block'>
                  <span className='form-label2 required'>Last Name</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('lastName')}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.lastName}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                {/* <div className='col form-block'>
                  <span className='form-label2 '>Email</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.email}</span>
                    </div>
                  )}
                </div> */}
                <div className='col form-block'>
                  <span className='form-label2 '>Gender</span>
                  <select className='form-control' {...formik.getFieldProps('gender')}>
                    <option key={'default'} value={'default'}>
                      Select Gender
                    </option>
                    <option key={'Male'} value={'Male'}>
                      Male
                    </option>
                    <option key={'Female'} value={'Female'}>
                      Female
                    </option>
                    <option key={'Other'} value={'Other'}>
                      Other
                    </option>
                    )
                  </select>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.gender}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2 required'>Country Code</span>
                  <div className='d-flex flex-column col'>
                    <select className='form-control' {...formik.getFieldProps('countryCode')}>
                      <option key={'default'} value={'default'}>
                        Select Country Code
                      </option>
                      {countryCodeList?.map((item: any) => {
                        return (
                          <option key={item.phoneCode} value={item.phoneCode}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.countryCode && formik.errors.countryCode && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.countryCode}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col form-block'>
                  <span className='form-label2 required'>Mobile Number</span>
                  <input
                    type={'number'}
                    onWheel={(e) => {
                      e.preventDefault() // Prevent mouse wheel and touchpad scrolling
                    }}
                    // Disable scrolling when focused
                    onFocus={(e) => e.target.addEventListener('wheel', (e) => e.preventDefault())}
                    className='form-control px-3 '
                    value={formik.values.mobileNumber}
                    onChange={(e: any) => changeMobileNo(e.target.value)}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.mobileNumber}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2'>Address</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('street')}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.street}</span>
                    </div>
                  )}
                </div>
                <div className='col form-block'>
                  <span className='form-label2'>City</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('city')}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.city}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2'>Address</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('street')}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.street}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2'>District</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('district')}
                  />
                  {formik.touched.district && formik.errors.district && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.district}</span>
                    </div>
                  )}
                </div>
                <div className='col form-block'>
                  <span className='form-label2'>Zip Code</span>
                  <input
                    type={'number'}
                    onWheel={(e) => {
                      e.preventDefault() // Prevent mouse wheel and touchpad scrolling
                    }}
                    // Disable scrolling when focused
                    onFocus={(e) => e.target.addEventListener('wheel', (e) => e.preventDefault())}
                    className='form-control px-3 '
                    value={formik.values.zipCode}
                    onChange={(e: any) => changezipcode(e.target.value)}
                  />
                  {formik.touched.zipCode && formik.errors.zipCode && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.zipCode}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2'>State</span>
                  <input
                    type={'text'}
                    className='form-control px-3 '
                    {...formik.getFieldProps('state')}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.state}</span>
                    </div>
                  )}
                </div>
                <div className='col form-block'>
                  <span className='form-label2'>Country</span>
                  <div className='d-flex flex-column col'>
                    <select className='form-control' {...formik.getFieldProps('country')}>
                      <option key={'default'} value={'default'}>
                        Select Country
                      </option>
                      {countryCodeList?.map((item: any) => {
                        return (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.country && formik.errors.country && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger'>{formik.errors.country}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col form-block'>
                  <span className='form-label2'>DOB</span>
                  <DatePicker
                    className='form-control'
                    selected={formik.values.dob ? new Date(formik.values.dob) : null}
                    dateFormat={'yyyy-MM-dd'}
                    onChange={(selectedDate: Date | null) => {
                      if (selectedDate) {
                        // setDate(selectedDate);
                        formik.setFieldValue('dob', moment(selectedDate).format('YYYY-MM-DD'))
                      }
                    }}
                    maxDate={new Date()}
                    popperProps={{
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          options: {
                            zIndex: 9999,
                          },
                        },
                      ],
                    }}
                  />

                  {formik.touched.dob && formik.errors.dob && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.dob}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='btn-row'>
              <button className='ePulse-btn-primary' type='submit'>
                {!isloading && 'Update'}
                {isloading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
