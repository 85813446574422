/* eslint-disable import/first */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import 'react-datepicker/dist/react-datepicker.css'
type Props = {
  className: string
}

import './../../../../App.css'
import {AppDatePicker} from '../../../layout/components/appComponents/DatePicker'

const TablesWidget10: React.FC<Props> = ({className}) => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='px-8 d-flex flex-direction-row align-items-center justify-content-between border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Reports</span>
        </h3>

        {/* <div
          className='card-toolbar bg-secondary rounded'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a href='#' className='btn btn-sm rounded text-black'>
            <KTSVG path='media/ePulse/plus.svg' className='svg-icon-3' />
            Add Files
          </a>
        </div> */}
        <button
          type='button'
          className='menu-link py-2 px-3 d-flex flex-row align-items-center border-0 ePulse-appColor-primary rounded'
        >
          <KTSVG path='/media/ePulse/plus.svg' className='svg-icon-7 me-2' />
          <span className=' fs-6 text-light'>Add Files</span>
        </button>
      </div>

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='separator my-2'></div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table   align-middle gs-0 gy-4 '>
            {/* begin::Table head */}
            {/* <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'></th>
                <th className='min-w-150px'>Authors</th>
                <th className='min-w-140px'>Company</th>
                <th className='min-w-120px'>Progress</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead> */}
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr className='border-bottom'>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack '>
                        <span className='text-primary me-2 fs-8 fw-semibold'>Patient Name</span>
                      </div>
                      <div className='d-flex flex-stack'>
                        <span className='text-black fs-3 fw-semibold'>Rohit Shrivastav</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Time Slot</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-normal'>5PM - 5:30PM</span>
                    </div>
                  </div>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Appointment Type</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-bold'>Follow Up</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center flex-shrink-0'>
                    <span className='text-primary fs-7 '>
                      <u>Change Status</u>
                    </span>
                  </div>
                </td>
              </tr>

              <tr className='border-bottom'>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack '>
                        <span className='text-primary me-2 fs-8 fw-semibold'>Patient Name</span>
                      </div>
                      <div className='d-flex flex-stack'>
                        <span className='text-black fs-3 fw-semibold'>Rohit Shrivastav</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Time Slot</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-normal'>5PM - 5:30PM</span>
                    </div>
                  </div>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Appointment Type</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-bold'>Follow Up</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center flex-shrink-0'>
                    <span className='text-primary fs-7 '>
                      <u>Change Status</u>
                    </span>
                  </div>
                </td>
              </tr>

              <tr className='border-bottom'>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack '>
                        <span className='text-primary me-2 fs-8 fw-semibold'>Patient Name</span>
                      </div>
                      <div className='d-flex flex-stack'>
                        <span className='text-black fs-3 fw-semibold'>Rohit Shrivastav</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Time Slot</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-normal'>5PM - 5:30PM</span>
                    </div>
                  </div>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Appointment Type</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-bold'>Follow Up</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center flex-shrink-0'>
                    <span className='text-primary fs-7 '>
                      <u>Change Status</u>
                    </span>
                  </div>
                </td>
              </tr>

              <tr className='border-bottom'>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack '>
                        <span className='text-primary me-2 fs-8 fw-semibold'>Patient Name</span>
                      </div>
                      <div className='d-flex flex-stack'>
                        <span className='text-black fs-3 fw-semibold'>Rohit Shrivastav</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Time Slot</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-normal'>5PM - 5:30PM</span>
                    </div>
                  </div>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack '>
                      <span className='text-primary me-2 fs-8 fw-semibold'>Appointment Type</span>
                    </div>
                    <div className='d-flex flex-stack'>
                      <span className='text-black fs-8 fw-bold'>Follow Up</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center flex-shrink-0'>
                    <span className='text-primary fs-7 '>
                      <u>Change Status</u>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget10}
