/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../../../../../../../App.css'
import {toAbsoluteUrl} from '../../../../../../../../_ePulse/helpers'

export function Template3(props: any) {
  const {
    name,
    qualification,
    address,
    email,
    mobileNumber1,
    mobileNumber2,
    hospital,
    website,
    backGroundImage,
    logoImage,
    signature,
  } = props

  const backgroundStyle = {
    backgroundImage: `url(${backGroundImage})`, // Set the value of the background-image property using the URL of the dynamic image
    backgroundSize: 'cover',
    // backgroundPosition: 'center center',
    // width: '100%',
    // height: '100vh',
  }
  return (
    <div className=' p-5' style={backgroundStyle}>
      <div className='d-flex flex-row justify-content-between w-100'>
        <div className='d-flex flex-column w-75 '>
          <div className=' fs-1 fw-bold'>Dr. {name}</div>
          <div>{qualification}</div>

          <div className='text-muted fs-9 mt-6'>Certification 1234567</div>
        </div>
        <div className='w-25 align d-flex flex-row justify-content-center align-items-center'>
          {logoImage && <img src={logoImage} width={50} height={40} alt='' className='me-3' />}
        </div>
      </div>
      {/* <div className='mt-9'>
        <div className='d-flex flex-row'>
          <div>Patient Name:</div>
          <div className='ms-2 col border-bottom border-dark' />
        </div>
        <div className='d-flex flex-row'>
          <div>Address:</div>
          <div className='col ms-2 border-bottom border-dark' />
        </div>
        <div className='d-flex flex-row'>
          <div className='w-150px d-flex flex-row '>
            <div>Age:</div>
            <div className='ms-2 border-bottom border-dark col' />
          </div>
          <div className='col d-flex flex-row ms-4'>
            <div>Date:</div>
            <div className='ms-2 border-bottom border-dark col' />
          </div>
        </div>
        <div className='d-flex flex-row'>
          <div>Diagnosis:</div>
          <div className='ms-2 col border-bottom border-dark' />
        </div>
      </div> */}
      <div className='h-300px bg-white w-100' />
      <div className='w-100 d-flex flex-row'>
        <div className='d-flex flex-row justify-content-end w-75' />

        <div className='d-flex flex-column align-items-center'>
          {signature && <img src={signature} className='rounded' width={80} height={40} alt='' />}
          <div className=' border-dark border-top'>Signature</div>
        </div>
      </div>
      <div className='d-flex flex-row mt-9  justify-content-end w-100 py-4 '>
        <div className='d-flex flex-column me-4'>
          <div className=' fs-3 fw-bold'>{hospital}</div>
          {/* <div>Slogan</div> */}
        </div>
        <div className='border-start my-1 border-3 border-dark me-3' />
        <div className='d-flex flex-column  w-50'>
          <div className='d-flex flex-row '>
            <div className='d-flex flex-row align-items-center col'>
              <img src={toAbsoluteUrl('/media/ePulse/call.svg')} alt='' width={15} height={15} />
              <div className='d-flex flex-column'>
                <span className='fs-9'>{mobileNumber1}</span>
                <span className='fs-9'>{mobileNumber2}</span>
              </div>
            </div>
            <div className='d-flex flex-row align-items-center col'>
              <img src={toAbsoluteUrl('/media/ePulse/email.svg')} alt='' width={15} height={15} />
              <div className='d-flex flex-column'>
                <span className='fs-9'>{email}</span>
              </div>
            </div>
          </div>
          <div className='d-flex flex-row mt-2'>
            <div className='d-flex flex-row align-items-center col'>
              <img
                src={toAbsoluteUrl('/media/ePulse/location.svg')}
                alt=''
                width={15}
                height={15}
              />
              <div className='d-flex flex-column'>
                <span className='fs-9'>{address}</span>
              </div>
            </div>
            <div className='d-flex flex-row align-items-center col'>
              <img src={toAbsoluteUrl('/media/ePulse/website.svg')} alt='' width={15} height={15} />
              <div className='d-flex flex-column'>
                <span className='fs-9'>{website}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
