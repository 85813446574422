/* eslint-disable jsx-a11y/anchor-is-valid */
import {TextField} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal, Row} from 'react-bootstrap'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import '../../App.css'
import {toAbsoluteUrl} from '../../_ePulse/helpers'

export function AddNewStaffLink(props: any) {
  const [mode, setMode] = useState<string>('EMAIL')
  const [role, setrole] = React.useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setrole(event.target.value as string)
  }
  return (
    <>
      <div>
        <Modal
          show={props.modalState}
          dialogClassName='modal-90w'
          onHide={() => props.setModalState(false)}
          backdrop='static'
        >
          <Modal.Header>
            <span className='text-black fs-1 fw-bold'>Add New Staff Member</span>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex flex-row px-4'>
              <div className='w-100 '>
                {/* <div className=' border-bottom border-grey mb-4'>
                  <span className='text-black fs-1 fw-bold '>Add New Staff Member</span>
                </div> */}
                <div className='mb-3'>
                  <span className='text-black fs-3 fw-bold '>Send Via</span>
                </div>
                <div className='row rounded border border-primary border-1'>
                  <div
                    className={
                      mode === 'EMAIL'
                        ? 'col rounded-start ePulse-appColor-primary text-center border-end border-primary py-2'
                        : 'col text-center border-end border-primary py-2'
                    }
                    onClick={() => setMode('EMAIL')}
                  >
                    <span
                      className={
                        mode === 'EMAIL'
                          ? 'text-white fs-5 fw-bold'
                          : 'ePulse-textColor-primary fs-5 fw-bold'
                      }
                    >
                      Email
                    </span>
                  </div>
                  <div
                    className={
                      mode === 'PHONE'
                        ? 'col ePulse-appColor-primary  text-center border-end border-primary py-2'
                        : 'col text-center   border-primary py-2'
                    }
                    onClick={() => setMode('PHONE')}
                  >
                    <span
                      className={
                        mode === 'PHONE'
                          ? 'text-white fs-5 fw-bold'
                          : 'ePulse-textColor-primary fs-5 fw-bold'
                      }
                    >
                      Phone No.
                    </span>
                  </div>
                </div>
                <TextField
                  className='my-6'
                  id='outlined-basic'
                  label='Type Here*'
                  placeholder='Type Here*'
                  variant='outlined'
                  size='small'
                  fullWidth
                />
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Role</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      size='small'
                      value={role}
                      label='Role'
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Doctor</MenuItem>
                      <MenuItem value={20}>Staff</MenuItem>
                      <MenuItem value={30}>Nurse</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className='row justify-content-center mt-5'>
              <button className='border-0 ePulse-appColor-primary py-2 w-50 fs-4 rounded-pill text-white'>
                Send Invite Link
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
