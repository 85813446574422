/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget14: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='px-8 border-0 mt-4'>
        <div className='d-flex flex-direcion-row justify-content-between col-12 align-items-center my-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Clinic Details</span>
          </h3>
          <h3 className=' align-items-start flex-column'>
            <span className=' fs-6 fw-normal align-items-start flex-column'>All Options</span>
          </h3>
        </div>
        <div className='separator mx-2' />
      </div>

      <div className='d-flex flex-direcion-row card-header border-0 justify-content-between mb-6'>
        <div className='col-5 bg-light my-6 mx-4 p-4'>
          <h2>
            <span className='fs-6 '>Associated Doctor</span>
          </h2>
          <div className='d-flex flex-direction-row'>
            <img
              src={toAbsoluteUrl('/media/avatars/300-14.jpg')}
              alt=''
              className='rounded-circle'
              width={80}
              height={80}
            />
            <div className='d-flex mx-5 flex-column'>
              <span className='text-primary card-label fw-bold fs-3'>Clinic Details</span>
              <span className='text-black card-label  fs-8 mb-1'>Phone No.</span>
              <span className='text-black card-label fw-bold fs-5 mb-1'>+911232453214</span>
              <button type='button' className='btn btn-outline-primary text-primary'>
                See Other Doctors
              </button>
            </div>
          </div>
        </div>
        <div className='col-6 bg-white my-6 mx-4'>
          <Link to={'/staff-managment'} className='d-flex align-items-sm-center mb-2 bg-light'>
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/ePulse/users.svg')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800  fs-6 fw-bold'>
                  View Staff Members
                </a>
              </div>
              <span className='badge badge-light fw-bold my-2'>
                <img
                  src={toAbsoluteUrl('/media/ePulse/rightArrow.png')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
          </Link>
          {/* <div className='d-flex align-items-sm-center mb-2 bg-light'>
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/ePulse/icons/header/chat.svg')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800  fs-6 fw-bold'>
                  Set Message Preset
                </a>
              </div>
              <span className='badge badge-light fw-bold my-2'>
                <img
                  src={toAbsoluteUrl('/media/ePulse/rightArrow.png')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
          </div> */}
          <div className='d-flex align-items-sm-center mb-2 bg-light'>
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/ePulse/watchPlusicon.svg')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>

            <div
              className='d-flex align-items-center flex-row-fluid flex-wrap'
              style={{cursor: 'pointer'}}
              onClick={() => navigate('/consulting_timing')}
            >
              <div className='flex-grow-1 me-2'>
                <span className='text-gray-800  fs-6 fw-bold'>Consultation Timings</span>
              </div>
              <span className='badge badge-light fw-bold my-2'>
                <img
                  src={toAbsoluteUrl('/media/ePulse/rightArrow.png')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget14}
