/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {useMutation, useQuery} from '@apollo/client'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {ToastMessageContainer} from '../../../../_ePulse/layout/components/ToastMessageContainer'
import {ChangeTemplate} from '../../DoctorModule/modules/settingsMain/appSettings/PDF/modals/ChangeTemplateModal'
import {Preview} from '../../DoctorModule/modules/settingsMain/appSettings/PDF/modals/Preview'
import {toAbsoluteUrl} from '../../../../_ePulse/helpers'
import {EDIT_APPOINTMENT_TIME} from '../../PharmaModule/GraphQLQueries/mutation'
import {GET_SCAN_APPOINTMENT_CANCLE_SETTING} from '../../PharmaModule/GraphQLQueries/querys'
import {EDIT_DETAIL} from '../../DoctorModule/GraphQLQueries/Doctor'
import useMutationApi from '../../../../hooks/useMutation'
import {LoadingSpinner} from '../../../../_ePulse/layout/components/LoadingSpinner'

function CancellationSetting() {
  const dataUser: any = useSelector((state) => {
    return state
  })

  const [isloading, setLoading] = useState(false)
  const [mris, setmri] = useState<any>()
  const [mrid, setmrid] = useState<any>('m')
  const [id, setId] = useState<any>('m')

  const {
    data: respdfData,
    loading: loading4,
    error: error4,
    refetch: refetch4,
  } = useQuery(GET_SCAN_APPOINTMENT_CANCLE_SETTING)

  useEffect(() => {
    setLoading(true)
    if (loading4) {
      setLoading(false)

      return
    }

    if (error4) {
      setLoading(false)

      return
    }

    if (respdfData) {
      const data = respdfData.getScanAppointmentCancellationSettings
      setmri(parseInt(data.appointmentCancellationBeforeTime))
      setmrid(data.appointmentCancellationBeforeUnit)
      setId(data.id)
      setLoading(false)
    }
  }, [respdfData, loading4, error4])

  const [errors, setErrors] = useState({})
  const validateFields = () => {
    const newErrors: any = {}
    if (!mris || parseInt(mris) <= 0) {
      newErrors.mris = 'Field Cannot Be empty Or Less than 1'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const {createData, loading, error, data} = useMutationApi(EDIT_DETAIL)

  const editCategoryCallAPI = async () => {
    setLoading(true)
    try {
      const res = await createData({
        userRoleId: id,
        appointmentCancellationBeforetime: parseInt(mris),
        appointmentCancellationBeforeUnit: mrid,
      })
      refetch4()
    } catch (error: any) {
      console.log('ERROR......', error)
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingSpinner isLoading={isloading} />

      <div className='d-flex flex-column form-wrapper'>
        <h2 className='page-title'>Appointment Cancellation Settings</h2>
        <hr />
        <div className='vertical-tabs'>
          <div className='tab-content'>
            <div className='d-flex flex-row w-100'>
              <div className='d-flex w-100 rounded ms-2 bg-light px-6 pt-6 pb-10 flex-column'>
                <div className=' mt-4 px-6'>
                  <div className='d-flex flex-row'>
                    <div className='d-flex flex-column col form-block'>
                      <span className='form-label required'>
                        Appointment Cancellation Default Time(in hour)
                      </span>
                      <input
                        type={'number'}
                        onWheel={(e) => {
                          e.preventDefault() // Prevent mouse wheel and touchpad scrolling
                        }}
                        // Disable scrolling when focused
                        className='form-control  px-3'
                        // min={5}
                        // max={60}
                        value={mris}
                        onChange={(e) => {
                          setmri(parseInt(e.target.value))
                        }}
                      />
                    </div>
                    <div className='d-flex flex-column col form-block'>
                      <span className='form-label required'>Appointment Duration Unit</span>

                      <select
                        className='form-control'
                        value={mrid}
                        onChange={(e) => {
                          setmrid(e.target.value)
                        }}
                      >
                        <option key={'m'} value={'m'}>
                          Hour
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='btn-row my-4'>
                  <button
                    className='ePulse-btn-primary'
                    type='button'
                    onClick={() => editCategoryCallAPI()}
                  >
                    {!isloading && 'Save'}
                    {isloading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CancellationSetting
