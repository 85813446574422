/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {TextField} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal, Row} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../../../../_ePulse/helpers'

import '../../../../../../../../App.css'
import {Template1} from '../Templates/template1'
import {Template2} from '../Templates/template2'
import {Template3} from '../Templates/template3'
import {Template4} from '../Templates/template4'

// const items = [Template1, Template2, Template3, Template4]

export function Preview(props: any) {
  const [selectedTemplate, setSelectedTemplate] = useState<any>(props.selectedTemplate)
  const {
    name,
    qualification,
    address,
    email,
    mobileNumber1,
    mobileNumber2,
    hospital,
    website,
    backGroundImage,
    logoImage,
    signature,
  } = props

  console.log(props)
  return (
    <>
      <div>
        <Modal
          show={props.modalState}
          //   aria-labelledby='contained-modal-title-vcenter'
          size='lg'
          //   aria-labelledby='example-custom-modal-styling-title'
          dialogClassName='modal-90w'
          onHide={() => props.setModalState(false)}
        >
          <Modal.Header closeButton className='fw-bold fs-4'>
            Rx PDF Preview
          </Modal.Header>
          <Modal.Body className='px-4 d-flex flex-row'>
            <div className='col'>
              {selectedTemplate === 'template_1' && (
                <Template1
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  backGroundImage={backGroundImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
              {selectedTemplate === 'template_2' && (
                <Template2
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  backGroundImage={backGroundImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
              {selectedTemplate === 'template_3' && (
                <Template3
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  backGroundImage={backGroundImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
              {selectedTemplate === 'template_4' && (
                <Template4
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  backGroundImage={backGroundImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
