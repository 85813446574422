/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import {checkIsActive, KTSVG, toAbsoluteUrl, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  className?: string
  hasBullet?: boolean
  bsTitle?: string
  outside?: boolean
  heading?: string
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  className,
  bsTitle,
  heading,
  outside = false,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, '')
  const {config} = useLayout()
  const {aside} = config

  return (
    // <OverlayTrigger
    //   placement='right'
    //   delay={{show: 250, hide: 400}}
    //   overlay={(props) => (
    //     <Tooltip id='button-tooltip' {...props}>
    //       {bsTitle}
    //     </Tooltip>
    //   )}
    // >
    <div className={clsx('menu-item', isActive && 'here show', className)}>
      {/* {outside ? (
          <a
            href={to}
            target='_blank'
            className={clsx('menu-link menu-center', {active: isActive})}
          >
            {fontIcon && aside.menuIcon === 'font' && (
              <span className='menu-icon me-0'>
                <i className={clsx('bi', fontIcon, 'fs-2')}></i>
              </span>
            )}
          </a>
        ) : ( */}
      <>
        <Link
          className={clsx('menu-link menu-center', {active: isActive})}
          to={to}
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss='click'
          data-bs-placement='right'
          data-bs-original-title={bsTitle}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}

          {icon && aside.menuIcon === 'svg' ? (
            <span className='menu-icon p-3'>
              {/* <KTSVG path={icon} className='svg-icon-2' /> */}
              <img src={toAbsoluteUrl(icon)} alt='' className=' img-fluid' />
            </span>
          ) : (
            // <span className='menu-icon p-3 text-white fs-1 fw-normal'>{title}</span>
            <span className='menu-title'>{title}</span>
          )}
          {heading && <span className='menu-text'>{heading}</span>}
          {/* {fontIcon && aside.menuIcon === 'font' ? (
              <span className='menu-icon me-0'>
                <i className={clsx('bi', fontIcon, 'fs-2')}></i>
              </span>
            ) : (
              <span className='menu-title'>{title}</span>
            )} */}
        </Link>
        {children}
      </>
      {/* )} */}
    </div>
    // </OverlayTrigger>
  )
}

export {AsideMenuItem}
