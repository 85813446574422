import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import { useQueryRequest } from '../../../../../../../common/modules/apps/user-management/users-list/core/QueryRequestProvider'


type Data = {
  id: any
  email: any
  mobileNumber: any
  userRole: any
  userSubRole: any
  userStatus: any
  userProfile: any
}

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<Data>>
}

const CommonHeader: FC<Props> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()
  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === 'name'
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  // const sortColumn = () => {
  //   // avoid sorting for these columns
  //   if (id === 'actions') {
  //     return
  //   }

  //   if (!isSelectedForSorting) {
  //     // enable sort asc
  //     updateState({sort: 'name', order: 'asc', ...initialQueryState})
  //     return
  //   }

  //   if (isSelectedForSorting && order !== undefined) {
  //     if (order === 'asc') {
  //       // enable sort desc
  //       updateState({sort: 'name', order: 'desc', ...initialQueryState})
  //       return
  //     }

  //     // disable sort
  //     updateState({sort: undefined, order: undefined, ...initialQueryState})
  //   }
  // }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      // onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export {CommonHeader}
