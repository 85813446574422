import {gql} from '@apollo/client'

export const GET_PDF_PRESCRIPTION = gql`
  query getDoctorPrescription($doctorId: ID!) {
    getDoctorPrescription(doctorId: $doctorId) {
      id
      formKeyValues {
        doctorName
        doctorQualification
        doctorSpeciality
        showSpeciality
        phoneNo1
        showPhoneNo1
        phoneNo2
        showPhoneNo2
        email
        showEmail
        address
        showAddress
        hospitalName
        showHospital
        websiteUrl
        showWebsite
        headerContent
        footerContent
      }
      templateName
      doctorId
      image
      createdDate
      clinicLogo
      doctorSignature
      mciNumber
      createdBy
      __typename
    }
  }
`

export const SET_PDF_PRESCRIPTION = gql`
  mutation setDoctorPrescriptionData(
    $doctorId: ID!
    $formKeyValues: formKeyValuesInput
    $doctorSignature: FileInput
    $clinicLogo: FileInput
  ) {
    setDoctorPrescriptionData(
      prescriptionInput: {
        doctorId: $doctorId
        formKeyValues: $formKeyValues
        doctorSignature: $doctorSignature
        clinicLogo: $clinicLogo
      }
    )
  }
`

export const CHANGE_PRESCRIPTION = gql`
  mutation changePrescriptionTemplate($doctorId: ID!, $templateName: String!) {
    changePrescriptionTemplate(doctorId: $doctorId, templateName: $templateName)
  }
`
