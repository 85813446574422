/* eslint-disable jsx-a11y/alt-text */

import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useMutation, useQuery, useLazyQuery} from '@apollo/client'
import {toast} from 'react-toastify'
import {IMPORT_PHARMACY_PATIENTS, UPLOAD_INVENTORY} from '../../../../GraphQLQueries/mutation'
import {GET_SAMPLE_PATIENT_FILE} from '../../../../GraphQLQueries/querys'
import {LoadingSpinner} from '../../../../../../../_ePulse/layout/components/LoadingSpinner'

export const Popup = (props: any) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState('')
  const [base64String, setBase64String] = useState('')
  const [isloading, setLoading] = useState(false)

  const formatFileSize = (file: any) => {
    const fileSizeInKB = file / 1024 // 1 KB = 1024 Bytes
    const fileSizeInMB = fileSizeInKB / 1024 // 1 MB = 1024 KB

    if (fileSizeInMB >= 1) {
      return fileSizeInMB.toFixed(2) + ' MB'
    } else {
      return fileSizeInKB.toFixed(2) + ' KB'
    }
  }

  const handelFileChange = (event: any) => {
    const file = event.target.files[0]
    if (
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv'
    ) {
      setFileName(file.name)
      setFileSize(formatFileSize(file.size))

      setErrorMessage('')

      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          const b64 = reader.result as string
          setBase64String(b64?.replace(/^data:[^;]+;base64,/, ''))
        }
        reader.readAsDataURL(file)
      }
    } else {
      setErrorMessage('File type Must be Excel or Csv')
    }
  }

  const [uploadFiles, {data, error}] = useMutation(IMPORT_PHARMACY_PATIENTS, {
    onCompleted: (data: any) => {
      props.refetch()
      reset()
      setLoading(false)
    },
    onError: (error: any) => {
      if (error?.networkError?.statusCode === 440) {
        toast.error(`${error?.networkError?.result?.errors[0]?.message}`)
        setLoading(false)
      } else {
        toast.error(`${error?.message}`)
        setLoading(false)
      }
    },
  })

  const uploadFile = async () => {
    try {
      setLoading(true)
      await uploadFiles({
        variables: {
          fileDataString: base64String,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const reset = () => {
    setErrorMessage('')
    setFileName('')
    setFileSize('')
    props.setModalState(false)
  }

  const [getPatientSampleFile, {data: dataPlan, loading: loadingPlan, error: errorPlan, refetch}] =
    useLazyQuery(GET_SAMPLE_PATIENT_FILE)

  const handleDownload = async () => {
    await getPatientSampleFile({
      onCompleted: async (data) => {
        let data1 = data.getPatientSampleFile

        if (data1) {
          const link = document.createElement('a')
          link.href = data1
          link.download = 'sample vendor'
          link.click()
        }
      },
      onError: (errors) => {
        console.log('error', errors)
      },
      fetchPolicy: 'cache-and-network',
    })
  }

  const removeFile = () => {
    setFileName('')
    setFileSize('')
    setErrorMessage('')
  }

  return (
    <>
      <LoadingSpinner isLoading={isloading} />
      <Modal
        show={props.modalState}
        dialogClassName='ePulse-modal ePulse-modal-50w'
        onHide={() => props.setModalState(false)}
        backdrop='static'
      >
        <Modal.Header className='border-0' closeButton onClick={reset}>
          <span className='fs-3 fw-bold'>Upload Patient</span>
        </Modal.Header>
        <Modal.Body className='container' />
        <div>
          <input
            type='file'
            id='upload'
            onChange={handelFileChange}
            hidden
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          />
          <label
            htmlFor='upload'
            className='ePulse-button3'
            style={{
              marginLeft: 16,
            }}
          >
            Choose File
          </label>
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {fileName}
          </span>
          {'   '}
          {fileSize !== '' ? <span>({fileSize})</span> : null}
          {fileName !== '' ? (
            <span style={{marginLeft: 16}} onClick={removeFile}>
              <img
                style={{width: 25, height: 25, cursor: 'pointer'}}
                src={'/media/ePulse/CrossIcon.png'}
              />
            </span>
          ) : null}
        </div>
        <p
          onClick={handleDownload}
          style={{padding: 10, textDecoration: 'underline', cursor: 'pointer', paddingLeft: 18}}
        >
          Download Sample file
        </p>

        {errorMessage !== '' ? (
          <p style={{color: 'red', marginLeft: 16, marginTop: 6}}>{errorMessage}</p>
        ) : null}
        <div className='display'>
          <button className='ePulse-button2' onClick={uploadFile}>
            Upload
          </button>
        </div>
      </Modal>
    </>
  )
}
