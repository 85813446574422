/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Modal} from 'react-bootstrap'

import '../../../../../../../../App.css'
import {Template1} from '../Templates/template1'
import {Template2} from '../Templates/template2'
import {Template3} from '../Templates/template3'
import {Template4} from '../Templates/template4'
import {toAbsoluteUrl} from '../../../../../../../../_ePulse/helpers'
import {useMutation} from '@apollo/client'
import {toast} from 'react-toastify'
import {success} from '../../../../../../../../data/Status/success'
import {CHANGE_PRESCRIPTION} from '../../../../../GraphQLQueries/PDFPrescription'
import {useSelector} from 'react-redux'
import useMutationApi from '../../../../../../../../hooks/useMutation'

const items = [
  {id: 'template_1', component: Template1, name: 'template_1'},
  {id: 'template_2', component: Template2, name: 'template_2'},
  {id: 'template_3', component: Template3, name: 'template_3'},
  {id: 'template_4', component: Template4, name: 'template_4'},
]

export function ChangeTemplate(props: any) {
  const dataUser: any = useSelector((state) => {
    return state
  })
  const [selectedTemplate, setSelectedTemplate] = useState<any>('template_1')
  const [selectedTemplatename, setSelectedTemplatename] = useState<any>('template_1')
  const [isloading, setLoading] = useState(false)
  const {
    name,
    qualification,
    address,
    email,
    mobileNumber1,
    mobileNumber2,
    hospital,
    website,
    doctorImage,
    logoImage,
    signature,
  } = props



  const {createData, loading, error, data} = useMutationApi(CHANGE_PRESCRIPTION)

  const setPrescriptionCallAPI = async () => {
    try {
      // await setPrescription({
      //   variables: {
      //     doctorId: dataUser?.auth?.userId,
      //     templateName: selectedTemplatename,
      //   },
      // })

      const res = await createData({
        doctorId: dataUser?.auth?.userId,
        templateName: selectedTemplatename,
      })
      toast.success(success.PRESCRIPTION_UPDATED)
      props.selectedTemplate(selectedTemplate)
      props.setModalState(false)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        <Modal
          show={props.modalState}
          //   aria-labelledby='contained-modal-title-vcenter'
          size='xl'
          //   aria-labelledby='example-custom-modal-styling-title'
          dialogClassName='ePulse-modal-90w'
          onHide={() => props.setModalState(false)}
        >
          <Modal.Header closeButton className='fw-bold fs-4'>
            Change Template
          </Modal.Header>
          <Modal.Body className='px-4 d-flex flex-row'>
            <div className='col-8 m-1'>
              {selectedTemplate === 'template_1' && (
                <Template1
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  doctorImage={doctorImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
              {selectedTemplate === 'template_2' && (
                <Template2
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  doctorImage={doctorImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
              {selectedTemplate === 'template_3' && (
                <Template3
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  doctorImage={doctorImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
              {selectedTemplate === 'template_4' && (
                <Template4
                  name={name}
                  qualification={qualification}
                  address={address}
                  email={email}
                  mobileNumber1={mobileNumber1}
                  mobileNumber2={mobileNumber2}
                  hospital={hospital}
                  website={website}
                  doctorImage={doctorImage}
                  signature={signature}
                  logoImage={logoImage}
                />
              )}
            </div>
            <div className='col-4 d-flex flex-column m-1'>
              <div
                style={{
                  // doctorColor: 'blue',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '1px',
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <div className='p-1 shadow-lg'>
                  <img
                    src={toAbsoluteUrl('/media/ePulse/template1.png')}
                    width={'150px'}
                    height={'200px'}
                    alt=''
                    className='me-3'
                    onClick={() => {
                      setSelectedTemplate('template_1')
                      setSelectedTemplatename('template_1')
                    }}
                    style={{cursor: 'pointer'}}
                  />
                </div>

                <div className='p-1 shadow-lg'>
                  <img
                    src={toAbsoluteUrl('/media/ePulse/templates.png')}
                    width={'150px'}
                    height={'200px'}
                    alt=''
                    className='me-3'
                    onClick={() => {
                      setSelectedTemplate('template_2')
                      setSelectedTemplatename('template_2')
                    }}
                    style={{cursor: 'pointer'}}
                  />
                </div>

                <div className='p-1 shadow-lg'>
                  <img
                    src={toAbsoluteUrl('/media/ePulse/templates.png')}
                    width={'150px'}
                    height={'200px'}
                    alt=''
                    className='me-3'
                    onClick={() => {
                      setSelectedTemplate('template_3')
                      setSelectedTemplatename('template_3')
                    }}
                    style={{cursor: 'pointer'}}
                  />
                </div>

                <div className='p-1 shadow-lg'>
                  <img
                    src={toAbsoluteUrl('/media/ePulse/templates.png')}
                    width={'150px'}
                    height={'200px'}
                    alt=''
                    className='me-3'
                    onClick={() => {
                      setSelectedTemplate('template_4')
                      setSelectedTemplatename('template_4')
                    }}
                    style={{cursor: 'pointer'}}
                  />
                </div>

                {/* templates */}
                {/* <div
                  // style={{
                  //   transform: 'translate(-34%, -34%) scale(0.3,0.3)',
                  // }}
                  onClick={() => setSelectedTemplate('Template1')}
                  className=' shadow-lg'
                >
                  <Template1
                    name={name}
                    qualification={qualification}
                    address={address}
                    email={email}
                    mobileNumber1={mobileNumber1}
                    mobileNumber2={mobileNumber2}
                    hospital={hospital}
                    website={website}
                    doctorImage={doctorImage}
                  />
                </div>
                <div
                  // style={{
                  //   // transform: 'translate(-4%, -30%) scale(0.9,0.4)',
                  //   transform: 'translate(-34%, -34%) scale(0.3,0.3)',
                  // }}
                  onClick={() => setSelectedTemplate('Template2')}
                  className=' shadow-lg'
                >
                  <Template1
                    name={name}
                    qualification={qualification}
                    address={address}
                    email={email}
                    mobileNumber1={mobileNumber1}
                    mobileNumber2={mobileNumber2}
                    hospital={hospital}
                    website={website}
                    doctorImage={doctorImage}
                  />
                </div> */}
                {/* <div
                  style={{
                    transform: 'translate(-4%, -30%) scale(0.9,0.4)',
                  }}
                  onClick={() => setSelectedTemplate('Template3')}
                  className=' shadow-lg'
                >
                  <Template3
                    name={name}
                    qualification={qualification}
                    address={address}
                    email={email}
                    mobileNumber1={mobileNumber1}
                    mobileNumber2={mobileNumber2}
                    hospital={hospital}
                    website={website}
                    doctorImage={doctorImage}
                  />
                </div>
                <div
                  style={{
                    transform: 'translate(-4%, -30%) scale(0.9,0.4)',
                  }}
                  onClick={() => setSelectedTemplate('Template4')}
                  className=' shadow-lg'
                >
                  <Template4
                    name={name}
                    qualification={qualification}
                    address={address}
                    email={email}
                    mobileNumber1={mobileNumber1}
                    mobileNumber2={mobileNumber2}
                    hospital={hospital}
                    website={website}
                    doctorImage={doctorImage}
                  />
                </div> */}
              </div>

              <div className='d-flex flex-row justify-content-end mt-6 '>
                <button
                  className='border border-dark col py-2 fs-5 rounded-pill  me-5'
                  onClick={() => props.setModalState(false)}
                >
                  <span className='fw-semibold'>Cancel</span>
                </button>
                <button
                  className='border-0 ePulse-appColor-primary col py-2 fs-5 rounded-pill text-white '
                  onClick={() => {
                    setPrescriptionCallAPI()
                  }}
                >
                  <span className='fw-semibold'>Select Template</span>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
