/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useLayout} from '../core'

const LoadingSpinner = (props: any) => {
  return (
    <React.Fragment>
      {(props.isLoading && props.isLoading === true && (
        <div
          style={{
            position: 'fixed',
            zIndex: '999',
            height: '100%',
            width: '100%',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              background: 'radial-gradient(rgba(15, 15, 15, .1), rgba(0, 0, 0, .1))',
            }}
          >
            <span
              className='indicator-progress'
              style={{
                display: 'inline-flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {/* <span
                className='spinner-border spinner-track spinner-dark my-3'
                style={{ color: '#000000', width: 45, height: 45, justifyContent: 'center', alignItems: 'center' }}
              > */}
              <img
                src='/media/ePulse/logo3.png'
                className='spinner-border spinner-track text-light my-3'
                alt='EPulse logo'
                style={{width: 30, height: 30}}
              />
              {/* </span> */}
              <span style={{fontSize: 20, color: '#000000', fontWeight: 'bold'}}>Loading ...</span>
            </span>
          </div>
        </div>
      )) ||
        null}
    </React.Fragment>
  )
}

export {LoadingSpinner}
