// useLazyQueryApi.js

import { useLazyQuery } from '@apollo/client'
import { gql } from 'graphql-tag'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { actionsUsers } from '../setup/redux/rootReducer'
import { useState } from 'react'
import { tr } from 'date-fns/locale'

const useLazyQueryApi = (toastmessage: any, query: any) => {
  const dispatch = useDispatch()
  const [errorMsg, seterrorMsg] = useState(false)
  const [getDataQuery, { loading, error, data }]: any = useLazyQuery(query)

  const fetchData = (variables: any) => {
    seterrorMsg(false)
    getDataQuery({
      variables: variables,
    })
  }

  if (error && !errorMsg) {
    seterrorMsg(true)
    const sessionExpirePattern = /session\s*expire/i

    // if (sessionExpirePattern.test(error?.networkError?.result?.errors[0]?.message)) {
    //   if (toastmessage) {
    //     toast.error('Session Expired! Please login again.')
    //     setTimeout(() => {
    //       dispatch(actionsUsers.logout())
    //     }, 3000)
    //   }
    // } else if (error.networkError.statusCode === 500) {
    //   toast.error(error?.networkError?.result?.errors[0]?.message)
    // } else {
    //   toast.error(`${error?.message}`)
    // }
  }

  return {
    fetchData,
    loading,
    error,
    data,
  }
}

export default useLazyQueryApi
