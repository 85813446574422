import {FC} from 'react'

const CellData = (props: any) => {
  return (
    <div className='d-flex ms-5 '>
      <span className='text-black fw-bold fs-7 '>{props?.tableProps}</span>
    </div>
  )
}

export {CellData}
